import React from 'react';
import Layout from '../components/Layout';
import Pagination from '../components/BlogPagination';
import { graphql } from 'gatsby';

const BlogPost = ({ data , pageContext: context}) => {
  const { markdownRemark: post } = data;
  const { previous, next } = context;
  return (
    <Layout>
      <article id="main">
      <header
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.8)), url(${post.frontmatter.featuredImage})`
        }}
      >
            <h2>{post.frontmatter.title}</h2>
            <p>{post.frontmatter.date}</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {(previous || next) &&
            <Pagination previous={previous} next={next} />
          }
        </div>
      </section>
    </article>
  </Layout>
  )
}

export default BlogPost;

export const blogQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage
      }
    }
  }
`