import React from 'react';
import { Link } from 'gatsby';
import { RiArrowRightLine, RiArrowLeftLine } from 'react-icons/ri';

const Pagination = ({ previous, next }) => (
    <div className="pagination -post">
        <ul>
            {(previous) && (
            <li>
                <Link to={previous.frontmatter?.slug} rel="prev">
                    <p>
                    <span className="icon -left"><RiArrowLeftLine/></span> Previous</p>
                    <span className="page-title">{previous.frontmatter?.title}</span>
                </Link>
            </li>
            )}
            {(next) && (
            <li>
                <Link to={next.frontmatter.slug} rel="next">
                <p>Next <span className="icon -right"><RiArrowRightLine/></span></p>
                <span className="page-title">{next.frontmatter.title}</span>
                </Link>
            </li>
            )}
        </ul>
    </div>
);
export default Pagination;